import App from "./app";
import { Root } from "./view/Root";
import { TokenManager } from "@circle/gestalt-app";

const init = async() => {
    const tokenManager = new TokenManager(window.config);

    const user = await tokenManager.init();
    const app  = new App({
        ...window.config,
        appId:    "license-manager",
        view:     Root,
        manager:  tokenManager,
        user:     user,
        rootNode: "page-container",
        log:      {
            level: "debug"
        }
    });

    await app.spawn();
};

init();
