import { useEffect } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { TextInput } from "@circle/kip-components";

const Search = () => {
    const { translate } = useTranslation();
    const { trigger }   = useTrigger();
    const { search }    = useBranch({
        search: ["search", "value"]
    });

    useEffect(() => {
        return () => trigger("search", "");
    }, []);

    return (
        <div className="center">
            <TextInput
                className="search-field"
                placeholder={translate("actions.search")}
                value={search}
                onChange={e => trigger("search", e.target.value)}
            />
        </div>
    );
};

export { Search };
