import { useState, useEffect } from "react";
import { useBranch, useTrigger, useTranslation } from "@circle/gestalt-app";
import { Datatable } from "./generic/Datatable";
import { Column } from "./generic/Column";
import { LicenseForm } from "./LicenseForm";
import { Search } from "./Search";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Button, Icon, Modal } from "@circle/kip-components";
import { Sidebar } from "./Sidebar";

const LicenseTable = () => { // eslint-disable-line max-statements
    const match                 = useParams();
    const navigate              = useNavigate();
    const location              = useLocation();
    const { translate }         = useTranslation();
    const [modal, setModal]     = useState(false);
    const [changed, setChanged] = useState(false);
    const [url, setUrl]         = useState(null);
    const { trigger }           = useTrigger();
    const { joinedLicenses, filteredLicenses } = useBranch({
        joinedLicenses:   ["joinedLicenses"],
        filteredLicenses: ["filteredLicenses"]
    });
    const license = joinedLicenses.find(x => x.id === match.id);

    useEffect(() => {
        if(license || !match.id || match.id === "create") return;

        navigate("../licenses");
    }, [license, match]);

    const openModal = path => {
        setUrl(path);
        return setModal(true);
    };
    const onClose = () => {
        if(changed) return openModal(url ?? location.pathname.split("/").slice(0, -1).join("/"));

        setChanged(false);
        setModal(false);

        return navigate(location.pathname.split("/").slice(0, -1).join("/"));
    };
    const onRowClick = x => {
        const basePath = match.id ? location.pathname.split("/").slice(0, -1).join("/") : location.pathname;
        const newUrl = `${basePath}/${x.id}`;

        if(match.id === x.id) return null;
        if(changed)           return openModal(newUrl);

        return navigate(newUrl);
    };
    const onSubmit = content => {
        trigger("createLicense", content);

        setChanged(false);
        navigate(location.pathname.split("/").slice(0, -1).join("/"));
    };
    const onEdit = content => {
        trigger("editLicense", content);

        setChanged(false);
        navigate(location.pathname.split("/").slice(0, -1).join("/"));
    };

    const setClose = () => {
        setModal(false);
        setChanged(false);

        navigate(url);
        setUrl(null);
    };

    return (
        <div className="flex-grow">
            <div className="flex">
                <Search />
                <Button
                    _variant="icon"
                    _appearance="primary"
                    className="add-button ml-5"
                    onClick={() => navigate(`${location.pathname}/create`)}
                >
                    <Icon _icon="Add" />
                </Button>
            </div>
            <Datatable
                content={filteredLicenses}
                isEditable={true}
                isDeletable={true}
                hasActionsColumn={true}
                onRowClick={x => onRowClick(x)}
                onDelete={id => trigger("deleteLicense", id)}
                selected={match.id}
            >
                <Column
                    title="customers.name"
                    width="45%"
                    item="customer"
                    render={x => <span>{x.name}</span>}
                />
                <Column
                    title="customers.license.expiry"
                    width="55%"
                    item="expiryDate"
                    render={x => <span>{x ? new Date(x).toLocaleDateString() : "-"}</span>}
                />
            </Datatable>
            <div>
                <Sidebar hidden={match.id !== "create" && !license} onClose={onClose}>
                    {
                        (match.id === "create" || license) &&
                        <LicenseForm
                            key={license?.id}
                            {...license}
                            onSubmit={match.id !== "create" ? onEdit : onSubmit}
                            onCancel={onClose}
                            onChange={(key, value, hasChanged) => setChanged(hasChanged)}
                        />
                    }
                </Sidebar>
            </div>
            <Modal
                isOpen={modal}
                onClose={() => setModal(false)}
                title={translate("modal.danger")}
                isCloseable={false}
            >
                <div className="flex-column">
                    {
                        translate("modal.unsaved_changes").split("\n").map((elem, key) => (
                            <span key={key} className="font-bold mt-3 center">{ elem }</span>
                        ))
                    }
                    <div className="flex mt-5 center">
                        <Button _appearance="primary" onClick={() => setModal(false)}>{translate("actions.no")}</Button>
                        <Button onClick={() => setClose(true)}>{translate("actions.yes")}</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export { LicenseTable };
