import { Route, Navigate, Routes } from "react-router-dom";
import { Customers } from "./Customers";
import { Licenses } from "./Licenses";

const Content = () => {
    return (
        <div id="page-content" className="page-content flex-grow">
            <div className="content-container">
                <Routes>
                    <Route exact path="/customers/:id" element={<Customers/>}/>
                    <Route exact path="/customers/*" element={<Customers/>}/>
                    <Route exact path="/licenses/*" element={<Licenses/>}/>
                    <Route exact path="/licenses/:id" element={<Licenses/>}/>
                    <Route path="*" element={<Navigate to="customers" replace />}/>
                </Routes>
            </div>
        </div>
    );
};

export { Content };
