import { ContentHeader } from "./ContentHeader";
import { LicenseTable } from "./LicenseTable";

const Licenses = () => {
    return (
        <div className="admin flex-column flex-grow">
            <ContentHeader headline="licenses.header"/>
            <LicenseTable />
        </div>
    );
};

export { Licenses };
