const toFakeUTC = input => {
    const utcDate = new Date(input);

    return new Date(new Date(new Date(new Date(utcDate.setFullYear(utcDate.getUTCFullYear())).setMonth(utcDate.getUTCMonth())).setDate(utcDate.getUTCDate())).setHours(utcDate.getUTCHours()));
};

const fromFakeUTC = input => {
    const utcDate = new Date(input);

    return new Date(new Date(new Date(new Date(utcDate.setUTCFullYear(utcDate.getFullYear())).setUTCMonth(utcDate.getMonth())).setUTCDate(utcDate.getDate())).setUTCHours(utcDate.getHours()));
};

export { toFakeUTC, fromFakeUTC };
