import { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./sidebar.module.scss";
import { Drawer } from "@circle/kip-components";

const Sidebar = ({ ...props }) => {
    const drawerRef = useRef(null);

    const handleOnClose = e => {
        if(!drawerRef && !drawerRef.current) return false;

        return e.target.nodeName !== "TD" && !drawerRef.current.contains(e.target) && props.onClose(e);
    };

    return (
        <Drawer ref={drawerRef} _show={!props.hidden} _onClose={handleOnClose} _position="right" className={styles.content}>
            { props.children }
        </Drawer>
    );
};

Sidebar.displayName = "Sidebar";
Sidebar.propTypes   = {
    hidden:   PropTypes.bool,
    children: PropTypes.node,
    onClose:  PropTypes.func
};

export { Sidebar };
