import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { Button } from "@circle/kip-components";

const FormActions = ({ ...props }) => {
    const { translate } = useTranslation();
    const onSubmit = e => {
        return props.onSubmit(e);
    };

    return (
        <div className={`actions-container flex${props.hidden ? " hidden" : ""}`}>
            <div className="horizontal-end flex-grow">
                <Button onClick={props.onCancel}>
                    <span>{ translate("actions.cancel") }</span>
                </Button>
                <Button _appearance="primary" disabled={!props.isValid} onClick={onSubmit}>
                    <span>{ translate("actions.save") }</span>
                </Button>
            </div>
        </div>
    );
};

FormActions.propTypes = {
    isValid:  PropTypes.bool,
    hidden:   PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func
};

export { FormActions };
