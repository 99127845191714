import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "@circle/gestalt-app";
import { Icon, Sidebar, SidebarButton, sidebarButtonHighlightClassName } from "@circle/kip-components";
import { resolveClassNames } from "palstek";

const Menu = () => {
    const { translate } = useTranslation();

    return (
        <Sidebar className="sidebar">
            <NavLink id="customers-view" to="/customers" className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, "menu-item")} rel="noopener noreferrer">
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Machinery" />
                    <Tooltip anchorSelect="#customers-view" effect="solid" place="right" content={translate("customers.header")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }} />
                </SidebarButton>
            </NavLink>
            <NavLink id="licenses-view" to="/licenses" className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, "menu-item")} rel="noopener noreferrer">
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Permissions" />
                    <Tooltip anchorSelect="#licenses-view" effect="solid" place="right" content={translate("licenses.header")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }} />
                </SidebarButton>
            </NavLink>
        </Sidebar>
    );
};

export { Menu };
