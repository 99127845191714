import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { uuid } from "@circle/actor";
import { TextInput } from "@circle/kip-components";
import { FormActions } from "./FormActions";
import md5 from "md5";


const CustomerForm = ({ onSubmit = x => x, onCancel = x => x, onChange = x => x, ...props }) => {
    const { translate }   = useTranslation();
    const [name, setName] = useState(props.name ?? "");
    const [hash]          = useState(md5(JSON.stringify(name)));
    const isValid         = name.length > 0;

    const _onSubmit = () => {
        if(!isValid) return null;

        return onSubmit({
            id:   props.id || uuid(),
            name: name
        });
    };

    const _onChange = e => {
        const value       = e.target.value;
        const currentHash = md5(JSON.stringify(value));

        setName(value);

        onChange("name", value, hash !== currentHash);
    };

    return (
        <>
            <div className="customer-form form">
                <span className="header font-bold">{ translate("sidebar.headline").toUpperCase() }</span>
                <div className="flex form-row">
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold mt-10">
                            { translate("customers.id") }
                        </span>
                        <TextInput
                            className="full-width"
                            isReadonly
                            value={props.id}
                        />
                    </div>
                </div>
                <div className="flex form-row mt-4">
                    <div className="flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("customer.ele.header") } *
                        </span>
                        <TextInput
                            className="full-width"
                            value={name}
                            onChange={_onChange}
                        />
                    </div>
                </div>
            </div>
            <FormActions
                isValid={isValid}
                onCancel={onCancel}
                onSubmit={_onSubmit}
            />
        </>
    );
};

CustomerForm.propTypes = {
    onSubmit: PropTypes.func,
    name:     PropTypes.string,
    id:       PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    onCancel: PropTypes.func,
    onChange: PropTypes.func
};

export { CustomerForm };
