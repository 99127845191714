import { App as BasicApp, computed, sync, action, translation, getLanguageBy } from "@circle/gestalt-app";
import { createBrowserHistory } from "history";

class App extends BasicApp {
    plants = sync("plants", {
        readonly: true
    });

    customers = sync("customers");
    licenses = sync("licenses");

    translations = translation({
        de: "/translations/texts_de.yml",
        en: "/translations/texts_en.yml"
    });

    static state = {
        history:      createBrowserHistory(),
        language:     getLanguageBy(window.config.languages.map(x => x.value)),
        default:      window.config.defaultLanguage,
        customers:    [],
        licenses:     [],
        plants:       [],
        translations: {},
        languages:    window.config.languages,
        filter:       { selected: "name", order: "asc", language: getLanguageBy(["de", "en"]) },
        search:       {
            value: ""
        }
    };

    // --- computations ---

    joinedLicenses = computed({
        customers: ["customers"],
        licenses:  ["licenses"],
        plants:    ["failsavePlants"]
    }, data => {
        return data?.licenses?.map(elem => Object.assign({}, elem, {
            customer: data.customers.find(x => elem.customerId?.includes(x.id)),
            licenses: elem.licenses.map(x => Object.assign({}, x, {
                plant: data.plants.find(plant => plant.id === x.plantId) // eslint-disable-line max-nested-callbacks
            }))
        }));
    });

    failsavePlants = computed({
        plants: ["plants"]
    }, data => {
        return data?.plants?.filter(x => x);
    });

    filteredLicenses = computed({
        licenses: ["joinedLicenses"],
        search:   ["search"]
    }, data => {
        return data?.licenses?.filter(x => x.customer?.name?.toLowerCase().includes(data.search.value.toLowerCase()));
    });

    filteredCustomers = computed({
        customers: ["customers"],
        search:    ["search"]
    }, data => {
        return data.customers.filter(x => x.name.includes(data.search.value));
    });

    // --- actions ---

    open = action(url => this.history(url));
    search = action(value => {
        this.state.select(["search", "value"]).set(value);
        this.state.commit();
    });

    deleteCustomer = action(id => {
        const idx = this.state
            .get("customers")
            .findIndex(x => x.id === id);

        this.state.unset(["customers", idx]);
        this.state.commit();
    });

    deleteLicense = action(id => {
        const idx = this.state
            .get("licenses")
            .findIndex(x => x.id === id);

        this.state.unset(["licenses", idx]);
        this.state.commit();
    });

    createCustomer = action(customer => {
        delete customer.id;

        this.state.push(["customers"], Object.assign({}, customer));
        this.state.commit();
    });

    editCustomer = action(customer => {
        const currentIdx = this.state
            .get("customers")
            .findIndex(x => x.id === customer.id);

        this.state.set(["customers", currentIdx], customer);
        this.state.commit();
    });

    createLicense = action(license => {
        delete license.id;

        this.state.push(["licenses"], Object.assign({}, license));
        this.state.commit();
    });

    editLicense = action(license => {
        const currentIdx = this.state
            .get("licenses")
            .findIndex(x => x.id === license.id);

        this.state.set(["licenses", currentIdx], Object.assign({}, license));
        this.state.commit();
    });
}

export default App;
