import PropTypes from "prop-types";
import { ContentHeader } from "./ContentHeader";
import { CustomersTable } from "./CustomersTable";

const Customers = ({ ...props }) => {
    return (
        <div className="flex-column flex-grow">
            <ContentHeader headline="customers.header"/>
            <CustomersTable match={props.match}/>
        </div>
    );
};

Customers.propTypes = {
    match: PropTypes.object
};

export { Customers };
